import axios from "axios";
import { BASE_URL, getUser } from "../config";

const user = JSON.parse(window.localStorage.getItem('user'));
// console.log(user?.token)
const header = {
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + user?.token
}

export const GetUsers = async (t) => {
    return await axios.get(`${BASE_URL}all-users`, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + t
        }
    })
}
export const GetDeletedUsers = async (t) => {
    return await axios.get(`${BASE_URL}deleted-users`, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + t
        }
    })
}
export const GetAllTransaction = async () => {
    return await axios.get(`${BASE_URL}all-transactions`, {
        "headers": header
    })
}
export const GetAllNotification = async () => {
    return await axios.get(`${BASE_URL}user/get-broadcast`, {
        "headers": header
    })
}
export const PostNotification = async (model) => {
    return await axios.post(`${BASE_URL}user/post-broadcast`, model, {
        "headers": header
    })
}
export const GetAllHistory = async () => {
    return await axios.get(`${BASE_URL}all-history`, {
        "headers": header
    })
}
export const GetPrices = async (tk) => {
    return await axios.get(`${BASE_URL}get-charges`, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + tk
        }
    })
}
export const GetTotalIncome = async () => {
    return await axios.get(`${BASE_URL}total-income`, {
        "headers": header
    })
}
export const PostUpdatePrice = async (model) => {
    return await axios.post(`${BASE_URL}update-charges`, model, {
        "headers": header
    })
}
export const UpdateUser = async (model, tk) => {
    return await axios.post(`${BASE_URL}user-id`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + tk
        }
    })
}
export const DeleteUser = async (model, tk) => {
    return await axios.post(`${BASE_URL}user/remove-user`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + tk
        }
    })
}
export const RestoreUser = async (model, tk) => {
    return await axios.post(`${BASE_URL}user/restore-user`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + tk
        }
    })
}
export const UpdateWallet = async (model, tk) => {
    return await axios.post(`${BASE_URL}user/update-wallet`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + tk
        }
    })
}

//pegeants endpoints
export const GetAllPegeants = async () => {
    return await axios.get(`${BASE_URL}pergents`, {
        "headers": header
    })
}
export const GetLatestPegeants = async () => {
    return await axios.get(`${BASE_URL}pergent`, {
        "headers": header
    })
}
export const PostPegeants = async (model) => {
    return await axios.post(`${BASE_URL}post-pergent`, model, {
        "headers": {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + user?.token
        }
    })
}
export const PostPegeantQueen = async (model) => {
    return await axios.post(`${BASE_URL}queens`, model, {
        "headers": {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + user?.token
        }
    })
}

export const PostVote = async (model, id) => {
    return await axios.post(`${BASE_URL}queen/vote/${id}`, model, {
        "headers": header
    })
}