import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetTVCable } from '../services/service'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const TVs = () => {
    const navigation = useNavigate();
    const { transInfo } = useSelector((state) => state.transInfo)
    const { user } = useSelector((state) => state.user)
    const [cables, setCables] = useState([])
    const amountCharge = 30;
    useEffect(() => {
        getCable();
    }, [])


    const getCable = () => {
        GetTVCable().then((response) => {
            // console.log(response)
            setCables(response.data?.data)
        })
            .catch(err => console.error(err))
    }

    const [selectedCable, setSelectedCable] = useState([])
    const onChangeCable = (item) => {
        setSelectedCable(item);
    }

    const handlePayTV = (item) => {

        navigation('/tv-details', {
            state: { model: item, charge: amountCharge }
        })

    }
    return (
        <div>
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"> <i className="icon-left" /> </a>
                        <h3>Bill</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">
                <div className="bill-content">
                    <div className="tf-container">
                        <h3 className="fw_6 mt-3">TV Cables</h3>
                        <ul className="mt-3 box-outstanding-service">
                            {cables?.map((item, index) => (
                                <li key={index} style={{ cursor: 'pointer' }} onClick={() => onChangeCable(item)}>
                                    <img src={item?.image} alt="" style={{ height: 20, width: 30, marginRight: 5, border: 'none' }} />
                                    <a href="#">{item?.tv_name}</a>
                                    {item?.id === selectedCable?.id ? (<span>✅</span>) : null}
                                </li>
                            ))}
                        </ul>

                        <h3 className="fw_6 d-flex justify-content-between mt-5">List of services <strong>TV</strong></h3>
                        <ul className="mt-3 mb-5">
                            {
                                selectedCable.cable_tv?.map((item, index) => (
                                    <li className="list-card-invoice" key={index}>
                                        <div className="logo">
                                            <span>✅</span>
                                        </div>
                                        <div className="content-right" onClick={() => handlePayTV(item)}>
                                            <h4><a>{item?.name}
                                                <span className="critical_color"><i className="icon-right"></i></span>
                                            </a>
                                            </h4>
                                            <p>
                                                ₦ {parseInt(item?.price) + amountCharge}
                                            </p>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>

                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default TVs