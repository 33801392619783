import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { ToastContainer, toast } from 'react-toastify'
import { CreateUserAccount } from '../services/service'
import { Link } from 'react-router-dom'

const UpdateAccount = () => {
    const { user } = useSelector((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState(user?.userData.name)
    const [email, setEmail] = useState(user?.userData.email)
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true)
        let model = {
            firstName, lastName, phone, email: user?.userData.email
        }
        await CreateUserAccount(model).then((res) => {
            setLoading(false)
            if (res.data.status) {
                window.localStorage.clear();
                toast.success(res.data.message + ", Please try to Login again")
            }
        }).catch(err => {
            setLoading(false)
            console.log(err)
            toast.error("Something went wrong")
        })
    }

    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header mb-1 is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/profile'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>Get Your Account Number</h3>
                    </div>
                    <div className='mt-3'>
                        {/* <h5 className="text-center">Update Your Profile</h5> */}
                        <form onSubmit={handleUpdate}>
                            <div>
                                <label>First Name:</label>
                                <input type="text" onChange={(e) => setFirstName(e.target.value)} defaultValue={user?.userData.name} required />
                            </div>
                            <div>
                                <label>Last Name:</label>
                                <input type="text" onChange={(e) => setLastName(e.target.value)} />
                            </div>
                            <div>
                                <label>Email:</label>
                                <input type="text" onChange={(e) => setEmail(e.target.value)} defaultValue={user?.userData.email} readOnly />
                            </div>
                            <div>
                                <label>Phone Number:</label>
                                <input type="text" onChange={(e) => setPhone(e.target.value)} defaultValue={user?.userData.phone} required />
                            </div>
                            <button type='submit' className='btn-outline-primary mt-3'>Get </button>
                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default UpdateAccount