import React, { useState } from 'react'
import Loader from '../../../components/Loader'
import { PostPegeantQueen, PostPegeants } from '../../../services/admin_service';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const CreatePageantQueen = () => {
    const navigation = useNavigate()
    let { id } = useParams();

    const [data, setData] = useState({
        pergent_id: id,
        queen_name: '',
        votes: 0,
        image: ''
    });
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null);
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(data)
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('pergent_id', data.pergent_id);
            formData.append('queen_name', data.queen_name);
            formData.append('votes', data.votes);

            // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
            PostPegeantQueen(formData).then((res) => {
                setLoading(false)
                console.log(res.data)
                if (res?.data.status) {
                    navigation('/admin/all-pageant')
                }
            })
                .catch(err => {
                    setLoading(false)
                    toast.error("somthing went wrong")
                    console.log(err)
                })
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }
    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            {/* /preload */}
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <a href="#" className="back-btn"> <i className="icon-left" /> </a>
                        <h3>Create Pegeants</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap" className="style1">
                <div className="tf-container">
                    <form onSubmit={handleSubmit}>
                        <h3>Please Fill Carefully</h3>

                        <div className="group-input">
                            <label>Season Title</label>
                            <input type="text" required value={data.queen_name} name='queen_name' onChange={handleChange} placeholder="queen name" />
                        </div>

                        <div className="group-input">
                            <label>Queen Image</label>
                            <input type="file" id="image" required accept="image/*" onChange={handleFileChange} />
                        </div>
                        <div className="group-input">
                            <button type='submit' className='btn-outline-primary'>'{loading ? 'Loading...' : 'Submit'}'</button>
                        </div>
                    </form>
                </div>
                <div style={{ marginBottom: 80 }}></div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default CreatePageantQueen