import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../config'

// export const loginUser = createAsyncThunk(
//     'user/login',
//     async (userModel) => {
//         const req = await axios.post(`${BASE_URL}login`, userModel);
//         const res = await req.data;

//         let response = {
//             token: res?.authorization.token,
//             wallet: res?.authorization.wallet, 
//             role: res?.user.role,
//             email: res?.user.email,
//             userData: res?.user,
//         }
//         window.localStorage.setItem('user', JSON.stringify(response));
//         return { response: response, charge: res?.datacharges };
//     }
// )


const initialState = {
    loading: false,
    user: window.localStorage.getItem('user') !== null ? JSON.parse(window.localStorage.getItem('user')) : null,
    charges: window.localStorage.getItem('charges') !== null ? JSON.parse(window.localStorage.getItem('charges')) : null,

};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.user = action.payload;
        },
        setCharges: (state, action) => {
            state.charges = action.payload;
        },
        logout: (state) => {
            state.user = null;
        },

    }
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(loginUser.pending, (state) => {
    //             state.loading = true;
    //             state.user = null;
    //             state.error = null;
    //         })
    //         .addCase(loginUser.fulfilled, (state, action) => {
    //             state.loading = false;
    //             state.user = action.payload.response;
    //             state.charges = action.payload.charge;
    //             state.error = null;
    //         })
    //         .addCase(loginUser.rejected, (state, action) => {
    //             state.loading = false;
    //             state.user = null;
    //             state.error = action.error.message;
    //         })
    // }
})

export const { loginUser, setCharges, logout } = userSlice.actions;
export default userSlice.reducer;

// const userSlice = createSlice({
//     name: 'user',
//     initialState: {
//         loading: false,
//         user: window.localStorage.getItem('user') !== null ? JSON.parse(window.localStorage.getItem('user')) : null,
//         error: null,

//     },
// })

// export default userSlice.reducer;