import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GetPrices, PostUpdatePrice } from '../../services/admin_service';
import Loader from '../../components/Loader';
import { useSelector } from 'react-redux';

const Settings = () => {
    const { user } = useSelector((state) => state.user)

    const navigation = useNavigate();
    const [updatePrice, setupdatePrice] = useState(false)
    const [admin, setAdmin] = useState(0)
    const [seller, setSeller] = useState(0)
    const [client, setClient] = useState(0)
    const [loading, setLoading] = useState(false)

    const handleLogout = () => {
        window.localStorage.removeItem('user');
        window.localStorage.clear();
        navigation('/login');
    }

    useEffect(() => {
        getCharges()
    }, [])

    const getCharges = async () => {
        await GetPrices(user?.token).then((res) => {
            // console.log(res.data.prices[0].admin)
            setAdmin(res.data.prices[0].admin)
            setClient(res.data.prices[0].client)
            setSeller(res.data.prices[0].seller)
        }).catch(err => {
            toast.error("Something went wrong please refresh the page")
        })
    }


    const handleChangePrice = async () => {
        setLoading(true)
        let model = {
            admin: parseInt(admin),
            seller: parseInt(seller),
            client: parseInt(client)
        }

        await PostUpdatePrice(model).then((el) => {
            setLoading(false)
            if (el.data.status) {
                toast.success("Updated successfully")
                window.location.reload();
            }
            console.log(el.data)
        }).catch(err => {
            setLoading(false)
            console.log(err.message)
            toast.error(err.message)
        })
    }

    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header mb-1 is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/admin'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>Settings</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">

                <div className="container">
                    <ul className="mt-1">
                        <li>
                            <a className="list-profile outline" style={{ cursor: 'pointer' }} onClick={() => setupdatePrice(!updatePrice)}>
                                <p>Update Price Charges</p>
                                <span><i className="icon-right" /></span>
                            </a>
                            {updatePrice && (
                                <>

                                    <hr />
                                    <div className="d-flex">
                                        <div>
                                            <label>Admin Charge</label>
                                            <input type="number" value={admin} onChange={(e) => setAdmin(e.target.value)} placeholder='admin price' />
                                        </div>
                                        <button onClick={handleChangePrice} style={{ color: 'purple', width: '80%', marginLeft: 10 }}>Update✔️</button>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <label>Seller Charge</label>
                                            <input type="number" value={seller} onChange={(e) => setSeller(e.target.value)} placeholder='Seller price' />
                                        </div>
                                        <button onClick={handleChangePrice} style={{ color: 'purple', width: '80%', marginLeft: 10 }}>Update✔️</button>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <label>Client Charge</label>
                                            <input type="number" value={client} onChange={(e) => setClient(e.target.value)} placeholder='Client price' />
                                        </div>
                                        <button onClick={handleChangePrice} style={{ color: 'purple', width: '80%', marginLeft: 10 }}>Update✔️</button>
                                    </div>
                                </>
                            )}

                        </li>
                    </ul>
                </div>
                <ul className="box-settings-profile mt-1 mb-8">
                    <hr />
                    <li>
                        <Link to={'/admin/create-pageant'} className="list-setting-profile">
                            <span className="icon icon-logout" />
                            <p>Create Pageant</p>
                            <i className="icon-right" />
                        </Link>
                    </li>
                    <li>
                        <Link to={'/admin/all-pageant'} className="list-setting-profile">
                            <span className="icon icon-logout" />
                            <p>Pageants</p>
                            <i className="icon-right" />
                        </Link>
                    </li>

                </ul>
                <ul className="box-settings-profile mt-1 mb-8">
                    <hr />
                    <li>
                        <Link to={'/admin/update-cable'} className="list-setting-profile">
                            <span className="icon icon-logout" />
                            <p>Update Cable Price</p>
                            <i className="icon-right" />
                        </Link>
                    </li>
                    <li>
                        <a className="list-setting-profile" onClick={handleLogout}>
                            <span className="icon icon-logout" />
                            <p>Logout</p>
                            <i className="icon-right" />
                        </a>
                    </li>
                </ul>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Settings