import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GetAllPegeants } from '../../../services/admin_service'
import Loader from '../../../components/Loader'

const Pageants = () => {
    const navigation = useNavigate();
    const [pegeants, setPegeants] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getIncome()
    }, [])

    const getIncome = async () => {
        setLoading(true)
        await GetAllPegeants().then((res) => {
            setLoading(false)
            // console.log(res.data)
            if (res.data.status) {
                setPegeants(res.data.returnObj)
            }
            // setTotal(res.data.sum)
        }).catch(err => {
            // toast.error(err.message)
            setLoading(false)
            console.log(err.message)
        })
    }
    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            {/* /preload */}
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <a href="#" className="back-btn"> <i className="icon-left" /> </a>
                        <h3>Pegeants</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap" className="style1">
                <div className="tf-container">
                    <div className="tf-tab">
                        <ul className="menu-tabs tabs-food">
                            <li className="nav-tab active">All Pegeants</li>
                            <li className="nav-tab">
                                <Link to={'/admin/create-pageant'} className="btn">Create Pegeant</Link>
                            </li>
                        </ul>
                        <div className="content-tab pt-tab-space mb-5">
                            <div className="tab-gift-item">
                                {pegeants?.map((item, index) => (
                                    <div className="food-box" style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                        navigation('/admin/pageant', {
                                            state: {
                                                data: item
                                            }
                                        })
                                    }}>
                                        <div className="img-box">
                                            <img src={`https://ssubme.com/backend/manage/public/cover_image/${item?.cover_image}`} alt="images" />
                                            {/* <img src="https://ichef.bbci.co.uk/news/624/mcs/media/images/72037000/jpg/_72037705_019932218-1.jpg" alt="images" /> */}
                                            <span>NGN{item?.vote_price}</span>
                                        </div>
                                        <div className="content">
                                            <h4><a href="#">{item.season}</a> - {item?.start_date}</h4>
                                            <div className="rating mt-2">
                                                <span>from: {moment(item?.start_date).format('do MMM yyyy')} to: {moment(item?.end_date).format('do MMM yyyy')}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pageants