import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Footer = () => {
    const { user } = useSelector((state) => state.user)
    return (
        <>
            {user ? (<div className="bottom-navigation-bar">
                <div className="tf-container">
                    <ul className="tf-navigation-bar">
                        <li className="active"><Link className="fw_6 d-flex justify-content-center align-items-center flex-column" to="/"><i className="icon-home2" /> Home</Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to={"/history"}><i className="icon-history" /> </Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to={"/profile"}><i className="icon-user-outline" /> Profile</Link> </li>
                    </ul>
                    {/* <span class="line"></span> */}
                </div>
            </div>) : null}
        </>
    )
}

export default Footer