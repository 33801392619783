import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Forgot_Password } from '../../services/service'
import { ToastContainer, toast } from 'react-toastify';

const ForgotPassword = () => {
    const navigation = useNavigate()
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true)
        let model = {
            email: email
        }

        Forgot_Password(model).then((res) => {
            console.log(res.data)
            if (res.data.status) {
                toast.success(res.data.message)
                const data = {
                    response: res.data.otp,
                    email: email
                }
                navigation('/otp', {
                    state: { data }
                })
                // console.log(res.data);
            } else {
                setMessage(res.data.message)
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setMessage(err.response.data.message)
            console.error(err);
        })
    }

    return (
        <div className="mt-7 login-section">
            <div className="tf-container">
                <form className="tf-form" onSubmit={handleSubmit}>
                    <h1>Forgot Password</h1>
                    {message && (<div className='alert alert-danger'>{message}</div>)}
                    <div className="group-input">
                        <label>Email</label>
                        <input type="email" name='email' onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Example@gmail" />
                    </div>

                    <button type="submit" className="tf-btn accent large">{loading ? 'Loading...' : 'Send'}</button>
                </form>

                <p className="mb-9 fw-3 text-center ">Already have a Account? <a href="/login" className="auth-link-rg">Login</a></p>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ForgotPassword