import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import { PostNotification } from '../../services/admin_service'
import { toast } from 'react-toastify'

const CreateNotification = () => {
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        let model = {
            title, message
        }
        setLoading(true)
        await PostNotification(model).then((el) => {
            setLoading(false)
            if (el.data.status) {
                toast.success("Post Notification successfully");
                navigate('/admin')
            }

            console.log(el.data)
        }).catch(err => {
            setLoading(false)
            console.log(err.message)
            toast.error("Something went wrong please refresh the page")
        })
    }

    return (
        <div className="panel-box panel-up">
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header-transfer header-st2">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex align-items-center">
                        <Link to={'/admin'} className="clear-panel"><i className="icon-left on_surface_color" /></Link>
                        <h3 className>Create Notification</h3>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: 80 }}>
                <div className="tf-container">
                    <form onSubmit={handleSubmit}>
                        <div className="tf-form">
                            <div className="group-input input-field">
                                <label>Title</label>
                                <input type="text" onChange={(e) => setTitle(e.target.value)} className="search-field value_input st1" />
                            </div>
                        </div>
                        <div className="tf-form">
                            <div className="group-input input-field">
                                <label>Message</label>
                                <textarea required onChange={(e) => setMessage(e.target.value)} className="search-field value_input st1"></textarea>
                            </div>
                        </div>
                        <button className='btn-outline-primary'>{loading ? 'Loading...' : 'Post'}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateNotification