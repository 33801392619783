import React, { useState } from 'react'
import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../components/Loader'
import { Link } from 'react-router-dom'
import { GetAllNotification } from '../../services/admin_service'
import moment from 'moment'

const Notifications = () => {
    const [loading, setLoading] = useState(false)
    const [notis, setNotis] = useState([])

    useEffect(() => {
        getNotification()
    }, [])
    const getNotification = async () => {
        setLoading(true)
        await GetAllNotification().then((el) => {
            setLoading(false)
            if (el.data.status) {
                setNotis(el.data.notifications)
            }
            // console.log(el.data)
        }).catch(err => {
            setLoading(false)
            console.log(err.message)
            toast.error("Something went wrong please refresh the page")
        })
    }

    return (
        <div className="panel-box panel-up">
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header-transfer header-st2">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex align-items-center">
                        <Link to={'/admin'} className="clear-panel"><i className="icon-left on_surface_color" /></Link>
                        <h3 className>All Notification</h3>
                    </div>
                </div>
            </div>
            <div className="wrap-transfer-friends mt-3">
                <div className="tf-container">
                    <div style={{ marginTop: 50, marginBottom: 50 }}>

                        <div className="mt-4">
                            <a href={'/admin/create-notification'} className="btn btn-primary">Push Notification</a>
                            <ul className="tabs-list-item" style={{ display: 'block', marginTop: '30px' }}>
                                {notis?.map((el, index) => (
                                    <li key={index}>
                                        <h4>{el?.title}</h4>
                                        <p>{moment(el?.created_at).format("do MMM yyyy")}</p>
                                        <hr />
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Notifications