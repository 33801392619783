import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AdminTransfer = () => {
    const { user } = useSelector((state) => state.user)
    const [banks, setBanks] = useState(JSON.parse(localStorage.getItem('userInfo'))?.userObj.bank_accounts.accounts)
    //wallet_balance
    useEffect(() => {

    }, [])

    if (user?.role !== 'admin') {
        window.location.href = '/login';
        return
    }
    return (
        <div style={{ marginBottom: 60 }}>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center mb-5">
                        <Link to={"/admin"} className="back-btn"><i className="icon-left white_color" /></Link>
                        <h3 className="white_color">Banks To Topup on Wakasub</h3>
                    </div>
                    <p className='mx-auto text-center text-white'>Current Balance: <span style={{ fontSize: 20 }}>NGN {JSON.parse(localStorage.getItem('userInfo'))?.userObj.wallet_balance}</span></p>
                </div>
            </div>

            <div className="transfer-card mt-3">
                <div className="tf-container">

                    {banks?.map((el, index) => (
                        <div className="tf-card-block d-flex align-items-center justify-content-between" key={index}>
                            <div className="inner d-flex align-items-center">
                                <i className="logo icon-wallet-filled-money-tool" />
                                <div className="content">
                                    <h4> <a className="fw_6">{el?.bankName}</a></h4>
                                    <p> {el?.accountName}</p>
                                    <p style={{ fontSize: 24, fontWeight: '700' }}><a href={`tel:${el?.accountNumber}`}>{el?.accountNumber}</a></p>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            </div>
        </div>
    )
}

export default AdminTransfer