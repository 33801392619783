import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const PageantDetail = () => {
    const location = useLocation()
    const [pegeant, setPegeant] = useState({})
    // console.log(pegeant)
    useEffect(() => {
        setPegeant(location?.state?.data)
    }, [location?.data])

    return (
        <div>
            {/* {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>} */}
            <div className="">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/admin/all-pageant'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>{pegeant?.season}</h3>
                    </div>
                </div>
            </div>
            <div>
                <div className="mb-8">
                    <img src={`https://ssubme.com/backend/manage/public/cover_image/${pegeant?.cover_image}`} alt="" className='img-responsive' />
                    <div className="app-section bg_white_color giftcard-detail-section-1">
                        <div className="tf-container">
                            <div className="voucher-info">
                                <h2 className="fw_6">{pegeant?.season} - {pegeant?.start_date}</h2>
                                <a href="#" className="critical_color fw_6">NGN {pegeant?.vote_price}</a>
                            </div>
                            <div className="mt-2">
                                <a href="#" className="note-voucher">Voting on now</a>
                                <p className="mt-2 fw_4">Offers to {moment(pegeant?.end_date).format('do MMM yyyy')} </p>
                            </div>
                        </div>
                    </div>
                    <div className="app-section mt-1 bg_white_color giftcard-detail-section-2">
                        <div className="tf-container">
                            <div className="voucher-desc">
                                <h4 className="fw_6">Description</h4>
                                <p className="mt-1"><span>{pegeant?.description}</span> stores</p>
                            </div>
                        </div>
                    </div>
                    <div className="app-section mt-1 bg_white_color giftcard-detail-section-2">
                        <div className="tf-container">
                            <div className="d-flex justify-space-between align-item-center">
                                <h3>List of Queens </h3> &nbsp;&nbsp; <Link to={`/admin/add-queen/${pegeant?.id}`} className="btn btn-primary ml-4">Add Queen</Link>
                            </div>
                            <div className="tab-gift-item mt-5">
                                {pegeant?.queens?.map((item, index) => (
                                    <div className="food-box" style={{ cursor: 'pointer' }} key={index}>
                                        <div className="img-box">
                                            <img src={`https://ssubme.com/backend/manage/public/image/${item?.image}`} alt="images" />
                                            {/* <img src="https://ichef.bbci.co.uk/news/624/mcs/media/images/72037000/jpg/_72037705_019932218-1.jpg" alt="images" /> */}
                                            <span>{item?.votes}</span>
                                        </div>
                                        <div className="content">
                                            <h4>{item.queen_name}</h4>
                                            <p>Total Price: <b>{item?.votes * pegeant?.vote_price}</b></p>
                                            <div className="rating mt-2">
                                                <span>{moment(item?.end_date).format('do MMM yyyy')}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </div>
    )
}

export default PageantDetail