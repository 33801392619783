import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../config'
import { ToastContainer, toast } from 'react-toastify'

const UpdateCableprices = () => {
    const [loading, setLoading] = useState(false)
    const [tvData, setTvData] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}trans/get/cables`, {
            "headers": {
                'Content-Type': 'application/json'
            }
        }) // Use the correct endpoint
            .then(response => {
                // console.log(response.data)
                setTvData(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching TV data:', error);
            });
    }, []);

    const handlePriceChange = (tvId, variationId, newPrice) => {
        setTvData(prevData =>
            prevData.map(tv =>
                tv.id === tvId
                    ? {
                        ...tv,
                        cable_tv: tv.cable_tv.map(cable =>
                            cable.variation_id === variationId
                                ? { ...cable, price: newPrice }
                                : cable
                        ),
                    }
                    : tv
            )
        );
    };

    const handleUpdatePrices = () => {
        setLoading(true)
        const pricesToUpdate = tvData.flatMap(tv =>
            tv.cable_tv.map(cable => ({
                id: cable.id,
                price: cable.price,
            }))
        );

        // console.log("testing", pricesToUpdate);

        axios.post(`${BASE_URL}update-cableprices`, { prices: pricesToUpdate })
            .then(response => {
                setLoading(false)
                window.location.reload()
                toast.success(response.data);
            })
            .catch(error => {
                setLoading(false)
                console.error('Error updating prices:', error);
            });
    };

    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header mb-1 is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/admin'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>Update Cable price</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">

                <div className="container">
                    <div>
                        {tvData.map(tv => (
                            <div key={tv.id}>
                                <h2>{tv.tv_name}</h2>
                                <img src={tv.image} alt={tv.tv_name} style={{ height: 80, width: 100, margin: 20 }} />
                                <form key={tv.id}>
                                    {tv.cable_tv.map(cable => (
                                        <div key={cable.id}>
                                            <label><strong>{`${cable.name} Price:`}</strong></label>
                                            <input
                                                type="number"
                                                value={cable.price}
                                                onChange={(e) =>
                                                    handlePriceChange(tv.id, cable.variation_id, e.target.value)
                                                }
                                            />
                                        </div>
                                    ))}
                                </form>
                            </div>
                        ))}
                        <button className='btn-primary mt-3' type="button" onClick={handleUpdatePrices}>
                            {loading ? 'Loading...' : 'Update Prices'}
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default UpdateCableprices