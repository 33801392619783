import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


const AdminPrivateRoute = () => {
    const { user } = useSelector((state) => state.user)

    return user?.role === "admin" ? <Outlet /> : <Navigate to={"/login"} />
}

export default AdminPrivateRoute