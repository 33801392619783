import React, { useState } from 'react'
import { ResetPass } from '../../services/service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const ResetPassword = () => {
    const location = useLocation()
    const navigation = useNavigate()
    const { obj } = location.state;

    const [resetObj, setResetObj] = useState({
        email: obj,
        password: ''
    });

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const handleChange = (e) => {
        setResetObj({ ...resetObj, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        ResetPass(resetObj).then((res) => {
            if (res.data.status) {
                // console.log(res.data);
                setMessage("Password changed successfully");
            } else {
                toast.error(res.data.message)
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setMessage(err.response.data.message)
            console.error(err);
        })
    }
    return (
        <div>
            <div className="header">
                <div className="tf-container">
                    <div className="tf-statusbar br-none d-flex justify-content-center align-items-center">
                        <a href="/login" className="back-btn"> <i className="icon-left" /> </a>
                    </div>
                </div>
            </div>
            <div className="mt-3 register-section">
                <div className="tf-container">
                    <form className="tf-form" onSubmit={handleSubmit}>
                        {message && (<>
                            <div className='alert alert-success'>{message}, please login to your account <Link to={'/login'} className="btn btn-success">Login</Link></div>
                        </>)}
                        <h1>Reset Password</h1>

                        <div className="group-input">
                            <label>Email</label>
                            <input type="email" onChange={handleChange} value={resetObj.email} name='email' placeholder="Example@gmail" />
                        </div>
                        <div className="group-input auth-pass-input last">
                            <label>Password</label>
                            <input type='text' placeholder="6-20 characters" name='password' onChange={handleChange} />
                        </div>

                        {/* <div className="group-cb mt-5">
                            <input type="checkbox" defaultChecked className="tf-checkbox" />
                            <label className="fw_3">I agree to <a>Terms and condition</a> </label>
                        </div> */}
                        <button type="submit" className="tf-btn accent large">{loading ? 'Loading...' : 'Change Password'}</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    )

}

export default ResetPassword