import { GetBalance } from "./services/service"

// export const BASE_URL = "http://localhost:8000/api/"
export const BASE_URL = "https://ssubme.com/backend/api/"
export const External_URL = "https://ebills.ng/wp-json/api/v1/"
export const USERNAME = "Ssubme"
export const PASSWORD = "Omoti250@"
export const SERVER_TOKEN = 'Token db926db1f37520ff0cbf24aa57e4bf06759b4119'
export const SERVER_URL = 'https://www.maskawasub.com/api/'


export function getUser() {
    let user = localStorage.getItem('user');
    user = user ? JSON.parse(user) : null;
    return user;
}

export const getBalanceInfo = async (email, token) => {
    let model = {
        email: email
    }
    await GetBalance(model, token).then((el) => {
        if (el.data.status) {
            return el.data.balance?.balance
        }
    }).catch(err => {
        console.log(err)
    })
}