import React from 'react'

const Maitainance = () => {
    return (
        <div style={{ flex: 1, marginBottom: 100 }}>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"><i className="icon-left white_color" /></a>
                        <h3 className="white_color">Under Maintainance</h3>
                    </div>
                </div>
            </div>
            <div className="card-secton topup-content">
                <div className="tf-container">
                    <h2 className='text-center'>This Page is under Maintainance...</h2>
                </div>

            </div>

        </div>
    )
}

export default Maitainance