import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { UpdatePassword, UpdateUser } from '../../services/service';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/Loader';

const UpdateProfile = () => {
    // const navigation = useNavigate();
    const { user } = useSelector((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(user?.userData.name)
    const [phone, setPhone] = useState(user?.userData.phone)
    const [password, setPassword] = useState('')

    // console.log(user)
    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true)
        let model = {
            name, phone, email: user?.userData.email
        }
        await UpdateUser(model).then((res) => {
            setLoading(false)
            if (res.data.status) {
                toast.success(res.data.message + ", Please try to Login again")
            }
        }).catch(err => {
            setLoading(false)
            console.log(err)
            toast.error("Something went wrong")
        })
    }

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setLoading(true)
        let model = {
            password, email: user?.userData.email
        }
        await UpdatePassword(model).then((res) => {
            setLoading(false)
            console.log(res.data)
            if (res.data.status) {
                toast.success(res.data.message)
            }
        }).catch(err => {
            setLoading(false)
            console.log(err)
            toast.error("Something went wrong")
        })
    }

    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="header mb-1 is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/profile'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>Update Profile</h3>
                    </div>
                    <div className='mt-3'>
                        <h5 className="text-center">Update Your Profile</h5>
                        <form onSubmit={handleUpdate}>
                            <div>
                                <label>Name:</label>
                                <input type="text" onChange={(e) => setName(e.target.value)} defaultValue={user?.userData.name} />
                            </div>
                            <div>
                                <label>Phone Number:</label>
                                <input type="text" onChange={(e) => setPhone(e.target.value)} defaultValue={user?.userData.phone} />
                            </div>
                            <button type='submit' className='btn-outline-primary mt-3'>Update </button>
                        </form>
                    </div>
                    <hr />
                    <form onSubmit={handleUpdatePassword}>
                        <div>
                            <label>Current Password:</label>
                            <input type="text" />
                        </div>
                        <div>
                            <label>New Password:</label>
                            <input type="text" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button type='submit' className='mt-3 btn-primary'>Update Password</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default UpdateProfile