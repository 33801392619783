import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, setCharges } from '../store/userSlice'
import { Link, useNavigate } from 'react-router-dom'
import { GetBalance, LoginUser } from '../services/service'

const Login = () => {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const [changeEye, setChangeEye] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setMessage] = useState('')
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()

        LoginUser(loginData).then((res) => {
            setLoading(false)
            // console.log(res.data)

            let response = {
                token: res?.data.authorization.token,
                wallet: res?.data.authorization.wallet,
                role: res?.data.user.role,
                email: res?.data.user.email,
                userData: res?.data.user,
            }
            window.localStorage.setItem('user', JSON.stringify(response));

            dispatch(loginUser(response))
            navigation('/')

            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setMessage("Wrong Email / Password")
            // setMessage(err.response?.data.message)
            console.error(err);
        })
    }
    const handleInput = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value })
    }
    return (
        <div className="mt-7 login-section">
            <div className="tf-container">
                <img src="/assets/logo.png" alt="" style={{ height: 80, width: 80 }} />
                <form className="tf-form" onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    {error && (<div className='alert alert-danger'>{error}</div>)}
                    <div className="group-input">
                        <label>Email</label>
                        <input type="email" name='email' onChange={handleInput} value={loginData.email} placeholder="Example@gmail" />
                    </div>
                    <div className="group-input auth-pass-input last">
                        <label>Password</label>
                        <input type={`${changeEye ? 'text' : 'password'}`} className="password-input" placeholder="Password" name='password' onChange={handleInput} value={loginData.password} />
                        <a className="icon-eye password-addon" id="password-addon" onClick={() => setChangeEye(!changeEye)} />
                    </div>
                    <Link to={"/forgot-password"} className="auth-forgot-password mt-3"><u>Forgot Password?</u></Link>
                    <br />
                    <p className="auth-forgot-password mt-3">You can also contact us on <a href='tel:07036426747' className="auth-link-rg">07036426747</a> / <a href='tel:09050093006' className="auth-link-rg">09050093006</a></p>
                    <button type="submit" className="tf-btn accent large">{loading ? 'Loading...' : 'Log In'}</button>
                </form>

                <p className="mb-9 fw-3 text-center ">Already have a Account? <Link to={"/register"} className="auth-link-rg">Sign up</Link></p>

            </div>
        </div>
    )
}

export default Login