import axios from "axios";
import { BASE_URL, getUser } from "../config";


const user = JSON.parse(window.localStorage.getItem('user'));
const header = {
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + user?.token
}

export const GetAdInfo = async (model) => {
    return await axios.get(`${BASE_URL}info/user-info`, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const RegisterUser = async (model) => {
    return await axios.post(`${BASE_URL}register`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}

export const LoginUser = async (model) => {
    return await axios.post(`${BASE_URL}login`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const Forgot_Password = async (model) => {
    return await axios.post(`${BASE_URL}forgot-password`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const VerifyPassword = async (model) => {
    return await axios.post(`${BASE_URL}verify`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}
export const ResetPass = async (model) => {
    return await axios.post(`${BASE_URL}reset-password`, model, {
        "headers": {
            'Content-Type': 'application/json'
        }
    })
}

// get all networks
export const GetNetworkProviders = async () => {
    return await axios.get(`${BASE_URL}all-networks`, {
        "headers": header
    })
}
// get all networks with data plan
export const GetNetworkProviderData = async () => {
    return await axios.get(`${BASE_URL}all-servicedata`, {
        "headers": header
    })
}
//end network
export const Buyairtime = async (model) => {
    return await axios.post(`${BASE_URL}trans/buyairtime`, model, {
        "headers": header
    })
}
export const Buydata = async (model) => {
    return await axios.post(`${BASE_URL}trans/buydata`, model, {
        "headers": header
    })
}

export const DeductBalance = async (model) => {
    return await axios.post(`${BASE_URL}user/deduct-wallet`, model, {
        "headers": header
    })
}

export const PostTransaction = async (model) => {
    return await axios.post(`${BASE_URL}post-transaction`, model, {
        "headers": header
    })
}

export const GetHistory = async () => {
    return await axios.get(`${BASE_URL}trans/history`, {
        "headers": header
    })
}
export const GetBalance = async (model, token) => {
    return await axios.post(`${BASE_URL}trans/balance`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        }
    })
}

export const PostHistory = async (model) => {
    return await axios.post(`${BASE_URL}trans/history`, model, {
        "headers": header
    })
}
export const GetSingleHistory = async (model) => {
    return await axios.post(`${BASE_URL}all-history-user`, model, {
        "headers": {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + user?.token
        }
    })
}

export const GetTVCable = async () => {
    return await axios.get(`${BASE_URL}trans/get/cables`, {
        "headers": {
            'Content-Type': 'application/json',

        }
    })
}
export const GetElectricBill = async () => {
    return await axios.get(`${BASE_URL}trans/get/electric`, {
        "headers": {
            'Content-Type': 'application/json',

        }
    })
}

// profile infoooooo

export const UpdateUser = async (model) => {
    return await axios.post(`${BASE_URL}user/update`, model, {
        "headers": header
    })
}
export const CreateUserAccount = async (model) => {
    return await axios.post(`${BASE_URL}user/create-account`, model, {
        "headers": header
    })
}

export const UpdatePassword = async (model) => {
    return await axios.post(`${BASE_URL}user/password`, model, {
        "headers": header
    })
}