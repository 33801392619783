import React, { useEffect, useState } from 'react'
import { DeductBalance, GetBalance, GetElectricBill } from '../services/service';
import { GetVerifyCableCard, PayCable, PayElectric } from '../services/ExternalService';
import Loader from '../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Electricity = () => {
    const { user } = useSelector((state) => state.user)
    const [electrics, setElectrics] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [variation_id, setVariation_id] = useState("prepaid")
    const [meterNo, setMeterNo] = useState("")
    const [loader, setLoader] = useState(false)
    const [amount, setAmount] = useState("")
    const [ownerName, setOwnerName] = useState("")
    const [ownerAddress, setOwnerAddress] = useState("")

    const variationStyle = { backgroundColor: '#e3ffe3', color: '#18c718' }
    useEffect(() => {
        getBalanceInfo();
        getElectric();
    }, [])
    const amountCharge = 50;

    const [balance, setBalance] = useState(0)
    const getBalanceInfo = async () => {
        let model = {
            email: user?.email
        }
        setLoader(true)
        await GetBalance(model, user?.token).then((el) => {
            setLoader(false)
            if (el.data.status) {
                setBalance(el.data.balance?.balance)
            }
        }).catch(err => {
            console.log(err)
            setLoader(false)
        })
    }
    const getElectric = () => {
        GetElectricBill().then((response) => {
            setElectrics(response.data?.data)
            // console.log(response.data?.data)
        })
            .catch(err => console.error(err))
    }

    const onChangeCable = (item) => {
        setSelectedItem(item);
    }

    const handleCheckMeter = () => {
        console.log(meterNo)
        setLoader(true)
        GetVerifyCableCard(meterNo, selectedItem?.service_id, variation_id)
            .then(response => response.json())
            .then(data => {
                console.log("response", data)
                setLoader(false)
                if (data?.code === "success") {
                    setOwnerName(data?.data.customer_name)
                    setOwnerAddress(data?.data.customer_address)
                    toast.success(data.message)
                } else {
                    toast.error(data.message)
                }

            })
            .catch(error => {
                toast.error(error)
                setLoader(false)
                console.error(error)
            });
    }

    const handlePayElectric = () => {
        if (balance < parseInt(amount * amountCharge)) {
            return toast.error("Your Balance is less than the service you are buying")
        }
        setLoader(true)
        PayElectric(meterNo, selectedItem?.service_id, variation_id, parseInt(amount))
            .then(response => response.json())
            .then(data => {
                console.log("response", data)
                setLoader(false)
                if (data?.code === "success") {
                    Deduct();
                    setLoader(false)
                    toast.success(data.message)
                    window.location.href = "/"
                } else {
                    toast.error(data.message)
                }

            })
            .catch(error => {
                toast.error(error)
                setLoader(false)
                console.error(error)
            });
    }

    const Deduct = () => {
        let item = {
            email: user?.email,
            price: parseInt(amount * amountCharge),
            whatfor: "Electicity"
        }
        DeductBalance(item).then((er) => {
            console.log(er.data)
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <div>
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={"/"} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>Electricity</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">
                <div className="bill-content">
                    <div className="tf-container">
                        <h3 className="fw_6 mt-3">Electric bills</h3>
                        <ul className="mt-3 box-outstanding-service">
                            {electrics?.map((item, index) => (
                                <li key={index} style={{ cursor: 'pointer' }} onClick={() => onChangeCable(item)}>

                                    <a href="#">{item?.name}</a>
                                    {item?.service_id === selectedItem?.service_id ? (<span>✅</span>) : null}
                                </li>
                            ))}
                        </ul>



                        <h3 className="fw_6 d-flex justify-content-between mt-5">Service Details </h3>
                        {selectedItem && (
                            <>
                                <ul className="mt-3 box-outstanding-service">

                                    <li style={{ cursor: 'pointer', ...variation_id == "prepaid" ? variationStyle : {} }} onClick={() => setVariation_id('prepaid')}>
                                        <a>Prepaid</a>
                                    </li>
                                    <li style={{ cursor: 'pointer', ...variation_id == "postpaid" ? variationStyle : {} }} onClick={() => setVariation_id('postpaid')}>
                                        <a>Postpaid</a>
                                    </li>

                                </ul>
                                <ul className="mt-3 mb-5">
                                    <li className="list-card-invoice">
                                        <div className="logo">
                                        </div>
                                        {/* <div className="content-right" onClick={() => handlePayTV(item)}> */}
                                        <div className="content-right">
                                            <h4><a>{selectedItem?.name}</a></h4>
                                            <input type="number" className='my-2' placeholder='Meter Number' onChange={(e) => setMeterNo(e.target.value)} onBlur={handleCheckMeter} />
                                            <h6 className='text-success'>- {ownerName}</h6>
                                            <h6 className='text-success'>- {ownerAddress}</h6>

                                            <div className='text-center' style={{ margin: 'auto' }}> {loader && <Loader />} </div>

                                            {ownerName === "" && (<span className='text-danger'>Please verify your Meter Number</span>)}

                                        </div>
                                    </li>
                                    <li className="">
                                        <input type="number" className='my-2 form-control' placeholder='Amount' onChange={(e) => setAmount(e.target.value)} />
                                        <button type="button" className='bg-success d-block' disabled={ownerName == ""} onClick={handlePayElectric}>{loader ? 'Loading...' : 'Confirm'}</button>
                                    </li>
                                </ul>
                            </>
                        )}

                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Electricity