import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { GetBalance } from '../services/service';

const Profile = () => {
    const navigation = useNavigate();
    const { user } = useSelector((state) => state.user)
    const { transInfo } = useSelector((state) => state.transInfo)
    const [balance, setBalance] = useState(0)

    useEffect(() => {
        getBalanceInfo()
    }, [])

    const getBalanceInfo = async () => {
        let model = {
            email: user?.email
        }
        await GetBalance(model, user?.token).then((el) => {
            if (el.data.status) {
                setBalance(el.data.balance?.balance)
                // dispatch(getBalance(el.data.balance?.balance))
            }
        }).catch(err => {
            console.log(err)
        })
    }

    //logout
    const handleLogout = () => {
        window.localStorage.removeItem('user');
        window.localStorage.clear();
        navigation('/login');
    }
    return (
        <div>
            <div className="header mb-1 is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>Profile</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap">
                <Link to={'/update-profile'} className="box-profile mt-1" href="#">
                    <div className="inner d-flex align-items-center">
                        {/* <div className="box-avatar">
                             <img src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" alt="image" /> 
                            <span className="icon-camera-to-take-photos" />
                        </div> */}
                        <div className="info">
                            <h2 className="fw_8">{user?.userData.name}</h2>
                            <p>{user?.phone} <i className="icon-copy1" /></p>
                        </div>
                    </div>
                    <span><i className="icon-right" /></span>
                </Link>
                <ul className="mt-1">
                    <li>
                        <a href="#" className="list-profile outline">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.625 12V15.375C23.6244 15.5737 23.5452 15.7642 23.4047 15.9047C23.2642 16.0452 23.0737 16.1244 22.875 16.125H17.625C17.4263 16.1244 17.2358 16.0452 17.0953 15.9047C16.9548 15.7642 16.8756 15.5737 16.875 15.375V13.125C16.8756 12.9263 16.9548 12.7358 17.0953 12.5953C17.2358 12.4548 17.4263 12.3756 17.625 12.375H22.125V11.25H22.875C23.0737 11.2506 23.2642 11.3298 23.4047 11.4703C23.5452 11.6108 23.6244 11.8013 23.625 12ZM19.5 14.25C19.5 14.1017 19.456 13.9567 19.3736 13.8333C19.2912 13.71 19.1741 13.6139 19.037 13.5571C18.9 13.5003 18.7492 13.4855 18.6037 13.5144C18.4582 13.5433 18.3246 13.6148 18.2197 13.7197C18.1148 13.8246 18.0433 13.9582 18.0144 14.1037C17.9855 14.2492 18.0003 14.4 18.0571 14.537C18.1139 14.6741 18.21 14.7912 18.3333 14.8736C18.4567 14.956 18.6017 15 18.75 15C18.9487 14.9994 19.1392 14.9202 19.2797 14.7797C19.4202 14.6392 19.4994 14.4487 19.5 14.25Z" fill="#E03E3E" />
                                <path d="M20.49 0.375L23.625 9.2925L22.125 9.82875V7.5C22.1244 7.30127 22.0452 7.11085 21.9047 6.97033C21.7642 6.8298 21.5737 6.75059 21.375 6.75H20.7562L19.6987 3.7425C19.5193 3.80644 19.329 3.83425 19.1387 3.82433C18.9485 3.81441 18.7621 3.76696 18.5902 3.6847C18.4184 3.60245 18.2645 3.48701 18.1375 3.34504C18.0104 3.20308 17.9127 3.03738 17.85 2.8575L6.93 6.735V6.75H3.75C3.65704 6.75012 3.56423 6.74259 3.4725 6.7275L3.375 6.4575L16.14 1.92L20.49 0.375Z" fill="#36CE94" />
                                <path d="M1.9125 5.23875C1.98704 5.61758 2.17701 5.96406 2.45632 6.23061C2.73563 6.49717 3.0906 6.67074 3.4725 6.7275C3.56423 6.74259 3.65704 6.75012 3.75 6.75H21.375C21.5737 6.7506 21.7642 6.82981 21.9047 6.97033C22.0452 7.11085 22.1244 7.30127 22.125 7.5V12.375H17.625C17.4263 12.3756 17.2358 12.4548 17.0953 12.5953C16.9548 12.7359 16.8756 12.9263 16.875 13.125V15.375C16.8756 15.5737 16.9548 15.7642 17.0953 15.9047C17.2358 16.0452 17.4263 16.1244 17.625 16.125H22.125V20.25C22.125 20.7038 21.4538 21.375 20.625 21.375H15.375V15.375C15.3744 15.1763 15.2952 14.9859 15.1547 14.8453C15.0142 14.7048 14.8237 14.6256 14.625 14.625H2.625C2.42627 14.6256 2.23585 14.7048 2.09533 14.8453C1.9548 14.9859 1.87559 15.1763 1.875 15.375V21.375C1.47718 21.375 1.09564 21.217 0.81434 20.9357C0.533035 20.6544 0.375 20.2728 0.375 19.875V5.25C0.375 4.65327 0.612053 4.08097 1.03401 3.65901C1.45597 3.23706 2.02826 3 2.625 3H3.82875C3.35291 2.99238 2.89102 3.16082 2.5318 3.47298C2.17257 3.78513 1.94134 4.219 1.8825 4.69125C1.86398 4.8742 1.8741 5.05892 1.9125 5.23875Z" fill="#FEBD55" />
                                <path d="M19.6987 3.74254L20.7562 6.75004H6.92993V6.73504L17.8499 2.85754C17.9127 3.03743 18.0104 3.20312 18.1374 3.34509C18.2644 3.48705 18.4183 3.60249 18.5901 3.68475C18.762 3.767 18.9484 3.81446 19.1386 3.82438C19.3289 3.8343 19.5192 3.80649 19.6987 3.74254Z" fill="#2FB380" />
                                <path d="M18.75 15C19.1642 15 19.5 14.6642 19.5 14.25C19.5 13.8358 19.1642 13.5 18.75 13.5C18.3358 13.5 18 13.8358 18 14.25C18 14.6642 18.3358 15 18.75 15Z" fill="#2B2B37" />
                                <path d="M8.1076 3L15.3751 0.375L16.1251 1.875L16.1401 1.92L3.3751 6.4575L3.4726 6.7275C3.0907 6.67074 2.73573 6.49717 2.45642 6.23061C2.1771 5.96405 1.98714 5.61758 1.9126 5.23875L8.1076 3Z" fill="#2FB380" />
                                <path d="M8.10753 3L1.91253 5.23875C1.87413 5.05892 1.86401 4.8742 1.88253 4.69125C1.94137 4.219 2.1726 3.78513 2.53182 3.47298C2.89105 3.16082 3.35293 2.99238 3.82878 3H8.10753Z" fill="#BD6F08" />
                                <path d="M15.375 21.375V22.875C15.3744 23.0737 15.2952 23.2642 15.1547 23.4047C15.0142 23.5452 14.8237 23.6244 14.625 23.625H2.625C2.42627 23.6244 2.23585 23.5452 2.09533 23.4047C1.9548 23.2642 1.87559 23.0737 1.875 22.875V18H15.375V21.375Z" fill="#FF6161" />
                                <path d="M15.375 16.875V18H15H2.25H1.875V16.875H2.25H15H15.375Z" fill="#EDF4FA" />
                                <path d="M15.375 15.375V16.875H1.875V15.375C1.87559 15.1763 1.9548 14.9858 2.09533 14.8453C2.23585 14.7048 2.42627 14.6256 2.625 14.625H14.625C14.8237 14.6256 15.0142 14.7048 15.1547 14.8453C15.2952 14.9858 15.3744 15.1763 15.375 15.375Z" fill="#CB3541" />
                                <path d="M14.625 22.5H10.5C9.10509 22.4992 7.74474 22.066 6.60617 21.2601C5.46761 20.4542 4.60681 19.3153 4.14225 18H1.875V22.875C1.87559 23.0737 1.9548 23.2642 2.09533 23.4047C2.23585 23.5452 2.42627 23.6244 2.625 23.625H14.625C14.8237 23.6244 15.0142 23.5452 15.1547 23.4047C15.2952 23.2642 15.3744 23.0737 15.375 22.875V21.75C15.375 21.9489 15.296 22.1397 15.1553 22.2803C15.0147 22.421 14.8239 22.5 14.625 22.5Z" fill="#E03E3E" />
                                <path d="M4.14225 18C4.01286 17.6341 3.9155 17.2577 3.85125 16.875H1.875V18H4.14225Z" fill="#C1CFE8" />
                                <path d="M3.85125 16.875C3.78628 16.5035 3.75242 16.1272 3.75 15.75V14.625H2.625C2.42627 14.6256 2.23585 14.7048 2.09533 14.8453C1.9548 14.9858 1.87559 15.1763 1.875 15.375V16.875H3.85125Z" fill="#A81E29" />
                                <path d="M16.1251 1.87499L15.8206 1.26599L4.43185 5.37862C4.24893 5.44466 4.04752 5.43707 3.8701 5.35745C3.69267 5.27783 3.55311 5.13241 3.48085 4.95187L3.38297 4.70737L1.9126 5.23874C1.98714 5.61757 2.1771 5.96404 2.45642 6.2306C2.73573 6.49716 3.0907 6.67073 3.4726 6.72749L3.3751 6.45749L16.1401 1.91999L16.1251 1.87499Z" fill="#2AA173" />
                                <path d="M10.056 5.625H5.71688L3.375 6.4575L3.4725 6.7275C3.56423 6.74259 3.65704 6.75012 3.75 6.75H6.93V6.735L10.056 5.625Z" fill="#2FB380" />
                                <path d="M23.625 9.2925L22.3358 5.625H20.3625L20.7582 6.75H21.375C21.5738 6.75059 21.7642 6.8298 21.9047 6.97033C22.0452 7.11085 22.1245 7.30127 22.125 7.5V9.82875L23.625 9.2925Z" fill="#2FB380" />
                                <path d="M10.0559 5.625L6.92993 6.735V6.75H20.7562L20.3606 5.625H10.0559Z" fill="#2AA173" />
                                <path d="M23.25 15.375H19.5C19.3406 15.3746 19.183 15.3401 19.038 15.2738C18.893 15.2075 18.7639 15.111 18.6592 14.9906C18.4775 14.9697 18.3097 14.8827 18.1879 14.7461C18.0662 14.6096 17.9989 14.433 17.9989 14.25C17.9989 14.067 18.0662 13.8904 18.1879 13.7539C18.3097 13.6173 18.4775 13.5303 18.6592 13.5094C18.7639 13.389 18.893 13.2925 19.038 13.2262C19.183 13.1599 19.3406 13.1254 19.5 13.125H22.125C22.3239 13.125 22.5147 13.046 22.6553 12.9053C22.796 12.7647 22.875 12.5739 22.875 12.375V11.25H22.125V12.375H17.625C17.4263 12.3756 17.2358 12.4548 17.0953 12.5953C16.9548 12.7358 16.8756 12.9263 16.875 13.125V15.375C16.8756 15.5737 16.9548 15.7642 17.0953 15.9047C17.2358 16.0452 17.4263 16.1244 17.625 16.125H22.875C23.0737 16.1244 23.2642 16.0452 23.4047 15.9047C23.5452 15.7642 23.6244 15.5737 23.625 15.375V15C23.625 15.0995 23.5855 15.1948 23.5152 15.2652C23.4448 15.3355 23.3495 15.375 23.25 15.375Z" fill="#CB3541" />
                                <path d="M18 17.25H22.125V16.125H17.625C17.4929 16.1242 17.3634 16.0881 17.25 16.0204V16.5C17.25 16.6989 17.329 16.8897 17.4697 17.0303C17.6103 17.171 17.8011 17.25 18 17.25Z" fill="#FC9E20" />
                                <path d="M1.875 16.875V15.375C1.87559 15.1763 1.9548 14.9859 2.09533 14.8453C2.23585 14.7048 2.42627 14.6256 2.625 14.625H3.375V6.705C3.01367 6.63235 2.68195 6.45433 2.42167 6.19339C2.1614 5.93244 1.98422 5.60027 1.9125 5.23875C1.8741 5.05892 1.86398 4.8742 1.8825 4.69125C1.94134 4.219 2.17257 3.78513 2.5318 3.47298C2.89102 3.16082 3.35291 2.99238 3.82875 3H2.625C2.02826 3 1.45597 3.23706 1.03401 3.65901C0.612053 4.08097 0.375 4.65327 0.375 5.25V19.875C0.375 20.2728 0.533035 20.6544 0.81434 20.9357C1.09564 21.217 1.47718 21.375 1.875 21.375V16.875Z" fill="#FC9E20" />
                                <path d="M21 19.875H18C17.6022 19.875 17.2206 19.717 16.9393 19.4357C16.658 19.1544 16.5 18.7728 16.5 18.375V16.5C16.5 16.3011 16.421 16.1103 16.2803 15.9697C16.1397 15.829 15.9489 15.75 15.75 15.75H15.375V21.375H20.625C21.4538 21.375 22.125 20.7038 22.125 20.25V18.75C22.125 19.0484 22.0065 19.3345 21.7955 19.5455C21.5845 19.7565 21.2984 19.875 21 19.875Z" fill="#FC9E20" />
                            </svg>
                            <p>Account Balance</p>
                            <span>₦{balance} <i className="icon-right" /></span>
                        </a>
                    </li>
                </ul>
                <ul className="mt-1">
                    <li>
                        <Link to={"/history"} className="list-profile outline">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00011 1.25C4.62474 1.24954 4.2516 1.30757 3.89411 1.422C3.12543 1.65173 2.45208 2.12475 1.97529 2.76998C1.49849 3.4152 1.24401 4.19775 1.25011 5V10C1.25064 10.7292 1.54054 11.4284 2.05615 11.944C2.57176 12.4596 3.27092 12.7495 4.00011 12.75H8.00011C8.19902 12.75 8.38979 12.671 8.53044 12.5303C8.67109 12.3897 8.75011 12.1989 8.75011 12V5C8.74879 4.00585 8.35327 3.05279 7.6503 2.34981C6.94732 1.64684 5.99426 1.25133 5.00011 1.25Z" fill="#DA9B00" />
                                <path d="M22.75 6.00002V20C22.7503 20.3612 22.6793 20.7189 22.5412 21.0527C22.4031 21.3865 22.2006 21.6897 21.9451 21.9452C21.6897 22.2006 21.3865 22.4031 21.0527 22.5412C20.7189 22.6793 20.3612 22.7503 20 22.75H10C9.6388 22.7503 9.28108 22.6793 8.94732 22.5412C8.61356 22.4031 8.31029 22.2006 8.05488 21.9452C7.79947 21.6897 7.59691 21.3865 7.45881 21.0527C7.3207 20.7189 7.24975 20.3612 7.25001 20V4.25002C7.24852 3.54389 6.99799 2.86092 6.54253 2.32131C6.08707 1.78171 5.45586 1.42006 4.76001 1.30002C4.83454 1.26345 4.91708 1.24626 5.00001 1.25002H18C19.2595 1.25108 20.467 1.75186 21.3576 2.64243C22.2482 3.533 22.749 4.74057 22.75 6.00002Z" fill="#FECC0E" />
                                <path d="M15 8.75H12C11.8011 8.75 11.6103 8.67098 11.4697 8.53033C11.329 8.38968 11.25 8.19891 11.25 8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25H15C15.1989 7.25 15.3897 7.32902 15.5303 7.46967C15.671 7.61032 15.75 7.80109 15.75 8C15.75 8.19891 15.671 8.38968 15.5303 8.53033C15.3897 8.67098 15.1989 8.75 15 8.75Z" fill="white" />
                                <path d="M18 11.75H12C11.8011 11.75 11.6103 11.671 11.4697 11.5303C11.329 11.3897 11.25 11.1989 11.25 11C11.25 10.8011 11.329 10.6103 11.4697 10.4697C11.6103 10.329 11.8011 10.25 12 10.25H18C18.1989 10.25 18.3897 10.329 18.5303 10.4697C18.671 10.6103 18.75 10.8011 18.75 11C18.75 11.1989 18.671 11.3897 18.5303 11.5303C18.3897 11.671 18.1989 11.75 18 11.75Z" fill="white" />
                                <path d="M18 14.75H12C11.8011 14.75 11.6103 14.671 11.4697 14.5303C11.329 14.3897 11.25 14.1989 11.25 14C11.25 13.8011 11.329 13.6103 11.4697 13.4697C11.6103 13.329 11.8011 13.25 12 13.25H18C18.1989 13.25 18.3897 13.329 18.5303 13.4697C18.671 13.6103 18.75 13.8011 18.75 14C18.75 14.1989 18.671 14.3897 18.5303 14.5303C18.3897 14.671 18.1989 14.75 18 14.75Z" fill="white" />
                                <path d="M18 17.75H12C11.8011 17.75 11.6103 17.671 11.4697 17.5303C11.329 17.3897 11.25 17.1989 11.25 17C11.25 16.8011 11.329 16.6103 11.4697 16.4697C11.6103 16.329 11.8011 16.25 12 16.25H18C18.1989 16.25 18.3897 16.329 18.5303 16.4697C18.671 16.6103 18.75 16.8011 18.75 17C18.75 17.1989 18.671 17.3897 18.5303 17.5303C18.3897 17.671 18.1989 17.75 18 17.75Z" fill="white" />
                            </svg>
                            <p>History</p>
                            <span><i className="icon-right" /></span>
                        </Link>
                    </li>
                </ul>
                {!user?.userData.account_number && (
                    <ul className="mt-1">
                        <li>
                            <Link to={"/account-no"} className="list-profile outline">
                                <p>Get Account Number</p>
                                <span><i className="icon-right" /></span>
                            </Link>
                        </li>
                    </ul>)}
                <ul className="box-settings-profile mt-1 mb-8">
                    <li>
                        <a href="#" className="list-setting-profile">
                            <span className="icon " />
                            <p>Contact us</p>
                            <p><strong>07036426747</strong></p>
                            <i className="icon-right" />
                        </a>
                        <div className='card card-body mt-3'>
                            <ul>
                                <li>Whatsapp : <b><a href="https://wa.me'2347036426747">07036426747</a></b></li>
                                <li>Facebook: <b><a href="#"></a></b></li>
                                <li>instagram: <b><a href="#"></a></b></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a className="list-setting-profile" onClick={handleLogout}>
                            <span className="icon icon-logout1" />
                            <p>Logout</p>
                            <i className="icon-right" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Profile