import { BASE_URL, External_URL, PASSWORD, SERVER_TOKEN, SERVER_URL, USERNAME, getUser } from "../config"
import axios from 'axios'

const user = getUser();
const header = {
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + user?.token
}

export const GetVerifyCableCard = async (customer_id, service_id, variation_id) => {
    return fetch(`${External_URL}verify-customer?username=${USERNAME}&password=${PASSWORD}&customer_id=${customer_id}&service_id=${service_id}&variation_id=${variation_id}`, {
        method: "GET"
    });
}

export const PayCable = async (customer_id, service_id, variation_id, phone) => {
    return fetch(`${External_URL}tv?username=${USERNAME}&password=${PASSWORD}&phone=${phone}&service_id=${service_id}&smartcard_number=${customer_id}&variation_id=${variation_id}`, {
        method: "GET"
    });
}

export const PayElectric = async (customer_id, service_id, variation_id, amount) => {
    return fetch(`${External_URL}electricity?username=${USERNAME}&password=${PASSWORD}&phone=08167927876&service_id=${service_id}&meter_number=${customer_id}&variation_id=${variation_id}&amount=${amount}`, {
        method: "GET"
    });
}

// export const GetUserInfo = async () => {
//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//             url: SERVER_URL,
//             headers: {
//                 'Authorization': SERVER_TOKEN,
//                 'Content-Type': 'application/json'
//             }
//         };

//         axios(config)
//             .then(function (response) {
//                 const { user, notification, ...rest } = response.data
//                 const { email, pin, Account_Balance, bank_accounts } = user
//                 let userObj = { Account_Balance, bank_accounts };
//             })
//             .catch(function (error) {
//                 console.log(error);
//             });
// }

export const GetUserInfo = async () => {
    return await axios.get(`${BASE_URL}trans/getuserinfo`, {
        headers: header
    });
}

export const GetAdminBalance = async () => {
    return fetch(`${External_URL}balance?username=${USERNAME}&password=${PASSWORD}`, {
        method: "GET"
    });
}
