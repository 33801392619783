import React, { useState } from 'react'
import { RegisterUser } from '../services/service';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Register = () => {
    const navivation = useNavigate();
    const [register, setRegister] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        role: 'client'
    });

    const [loading, setLoading] = useState(false)
    const [changeEye, setChangeEye] = useState(false)
    const [message, setMessage] = useState('')
    const handleChange = (e) => {
        setRegister({ ...register, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        RegisterUser(register).then((res) => {
            // console.log(res)
            if (res.data.status) {
                setMessage("Your account was created successfully")
            } else {
                toast.err(res.data.message)
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message)
            console.log(err);
        })
    }
    return (
        <div>
            <div className="header">
                <div className="tf-container">
                    <div className="tf-statusbar br-none d-flex justify-content-center align-items-center">
                        <a href="/login" className="back-btn"> <i className="icon-left" /> </a>
                    </div>
                </div>
            </div>
            <div className="mt-3 register-section">
                <div className="tf-container">
                    {message && (<>
                        <div className='alert alert-success'>{message}, please check your email for the final steps</div>
                    </>)}
                    <img src="/assets/logo.png" alt="" style={{ height: 80, width: 80 }} />
                    <form className="tf-form" onSubmit={handleSubmit}>
                        <h1>Register</h1>
                        <div className="group-input">
                            <label>Name</label>
                            <input type="text" value={register.name} placeholder='Your name...' name='name' onChange={handleChange} />
                        </div>
                        <div className="group-input">
                            <label>Email</label>
                            <input type="email" onChange={handleChange} value={register.email} name='email' placeholder="Example@gmail" />
                        </div>
                        <div className="group-input">
                            <label>Phone Number</label>
                            <input type="text" name='phone' onChange={handleChange} value={register.phone} placeholder='Your Phone' />
                        </div>
                        <div className="group-input auth-pass-input last">
                            <label>Password</label>
                            <input type={`${changeEye ? 'text' : 'password'}`} placeholder="6-20 characters" name='password' onChange={handleChange} /> <a className="icon-eye password-addon" id="password-addon" onClick={() => setChangeEye(!changeEye)} />
                        </div>

                        <div className="group-cb mt-5">
                            <p className="fw_3">Already have an account? <Link to={'/login'}>Login</Link> </p>
                        </div>
                        <button type="submit" className="tf-btn accent large">{loading ? 'Loading...' : 'Create An Account'}</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Register