import React, { useEffect, useState } from 'react'
import { getUser } from '../config'
import { GetBalance } from '../services/service'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { GetUserInfo } from '../services/ExternalService'

const Home = () => {
    const { user } = useSelector((state) => state.user)
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState(0)
    const [isContact, setIsContact] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        getBalanceInfo()
        getUserOP()
    }, [])

    const getBalanceInfo = async () => {
        let model = {
            email: user?.email
        }
        setLoading(true)
        await GetBalance(model, user?.token).then((el) => {
            setLoading(false)
            if (el.data.status) {
                setBalance(el.data.balance?.balance)
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const getUserOP = async () => {
        setLoading(true)
        await GetUserInfo()
            .then((resp) => {
                // console.log(resp.data.data)
                let req = resp.data.data
                setLoading(false)
                // dispatch(getAppInfo(req))
                window.localStorage.setItem('userInfo', JSON.stringify(req));
            }).catch((err) => {
                setLoading(false)
                console.log("error", err)
            })
    }
    const handleLogout = () => {
        window.localStorage.removeItem('user');
        window.localStorage.clear();
        navigation('/login');
    }

    if (!user) {
        window.location.replace('/login');
        return
    }

    return (
        <div style={{ marginBottom: 80 }}>
            <div className="app-header">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-between align-items-center">
                        <Link className="user-info d-flex justify-content-between align-items-center" to={"/profile"}>
                            <img src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" alt="image" />
                            <div className="content">
                                <h4 className="white_color">{user?.userData.name} <Link to={'/admin'} style={{ color: 'yellow' }}>{user?.role === 'admin' ? "Go to Admin" : ""}</Link></h4>
                                <p className="white_color fw_4">Recharge made easy</p>
                            </div>
                        </Link>
                        <div className="">
                            <Link to={"/notifications"} id="btn-popup-up" className="icon-notification1"><span></span></Link>
                            <br />
                            <a id="btn-popup-up" onClick={handleLogout} className="icon-logout" style={{ color: '#fff' }}>logout<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-secton">
                <div className="tf-container">
                    <div className="tf-balance-box">
                        <div className="balance">
                            <div className="row">
                                <div className="col-6 br-right">
                                    <div className="inner-left">
                                        <p>Your Balance:</p>
                                        <h3>₦{balance}</h3>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="inner-right">
                                        <p></p>
                                        <h3>-
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wallet-footer">
                            <div className="tf-container">
                                <p className='text-primary text-bold mb-2 text-underline'>Fund Wallet</p>
                            </div>
                            <ul className="d-flex justify-content-between align-items-center">
                                <li className="wallet-card-item">
                                    <Link to={'/banktransfer'} className="fw_6 text-center" id="btn-popup-down">
                                        <ul className="icon icon-group-transfers">
                                            <li className="path1" />
                                            <li className="path2" />
                                            <li className="path3" />
                                        </ul>
                                        By Bank Transfer
                                    </Link>
                                </li>
                                <li className="wallet-card-item"><a className="fw_6" href="topup">
                                    <ul className="icon icon-topup">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                        <li className="path4" />
                                    </ul>
                                    Online Payment
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div className="tf-container">
                    <div className="tf-title d-flex justify-content-between">
                        <h3 className="fw_6">Your service</h3>
                    </div>
                    <ul className="box-service mt-3">
                        <li>
                            <Link to={'/airtime'}>
                                <div className="icon-box bg_color_1">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4355_17017)">
                                            <rect x="6.375" y={2} width={12} height={19} fill="white" />
                                            <path d="M17.7247 0H7.02484C6.13341 0 5.40381 0.728914 5.40381 1.62103V22.3783C5.40381 23.2704 6.13341 24 7.02484 24H17.7247C18.6161 24 19.3457 23.2718 19.3457 22.379V1.62103C19.3457 0.728914 18.6161 0 17.7247 0ZM10.6632 1.16846H14.0863C14.1947 1.16846 14.2824 1.25623 14.2824 1.36526C14.2824 1.4736 14.1947 1.56137 14.0863 1.56137H10.6632C10.5549 1.56137 10.4671 1.4736 10.4671 1.36526C10.4671 1.25623 10.5549 1.16846 10.6632 1.16846ZM12.3748 23.1895C11.927 23.1895 11.5643 22.8267 11.5643 22.3783C11.5643 21.9298 11.927 21.5678 12.3748 21.5678C12.8226 21.5678 13.1853 21.9298 13.1853 22.3783C13.1853 22.8267 12.8226 23.1895 12.3748 23.1895ZM18.2177 21H6.53181V2.57074H18.2177V21Z" fill="#283EB4" />
                                            <path d="M12.3749 6C13.9704 6 15.4719 6.6175 16.6019 7.7385C16.7979 7.9325 16.7994 8.2495 16.6049 8.445C16.5074 8.5435 16.3784 8.593 16.2499 8.593C16.1229 8.593 15.9954 8.5445 15.8979 8.448C14.9564 7.5145 13.7049 7 12.3749 7C11.0449 7 9.79344 7.5145 8.85194 8.4485C8.65644 8.643 8.33944 8.642 8.14494 8.4455C7.95044 8.2495 7.95194 7.9325 8.14794 7.7385C9.27794 6.6175 10.7794 6 12.3749 6Z" fill="#39A3F8" />
                                            <path d="M11.25 12.75H10C9.793 12.75 9.625 12.918 9.625 13.125C9.625 13.332 9.793 13.5 10 13.5H11.25C11.319 13.5 11.375 13.556 11.375 13.625V14.375H10C9.793 14.375 9.625 14.543 9.625 14.75C9.625 14.957 9.793 15.125 10 15.125H11.375V15.875C11.375 15.944 11.319 16 11.25 16H10C9.793 16 9.625 16.168 9.625 16.375C9.625 16.582 9.793 16.75 10 16.75H11.25C11.7325 16.75 12.125 16.3575 12.125 15.875V13.625C12.125 13.1425 11.7325 12.75 11.25 12.75Z" fill="#39A3F8" />
                                            <path d="M14.75 14.5H14C13.793 14.5 13.625 14.668 13.625 14.875C13.625 15.082 13.793 15.25 14 15.25H14.375V16H13.5C13.431 16 13.375 15.944 13.375 15.875V13.625C13.375 13.556 13.431 13.5 13.5 13.5H14.75C14.957 13.5 15.125 13.332 15.125 13.125C15.125 12.918 14.957 12.75 14.75 12.75H13.5C13.0175 12.75 12.625 13.1425 12.625 13.625V15.875C12.625 16.3575 13.0175 16.75 13.5 16.75H14.75C14.957 16.75 15.125 16.582 15.125 16.375V14.875C15.125 14.668 14.957 14.5 14.75 14.5Z" fill="#39A3F8" />
                                            <path d="M12.3751 7.89099C11.2896 7.89099 10.2671 8.31199 9.49706 9.07649C9.30106 9.27099 9.29956 9.58699 9.49406 9.78299C9.68906 9.97949 10.0051 9.98049 10.2011 9.78599C10.7831 9.20849 11.5551 8.89099 12.3751 8.89099C13.1951 8.89099 13.9671 9.20899 14.5491 9.78649C14.6466 9.88299 14.7736 9.93149 14.9011 9.93149C15.0296 9.93149 15.1586 9.88199 15.2561 9.78349C15.4506 9.58749 15.4491 9.27099 15.2531 9.07649C14.4831 8.31199 13.4606 7.89099 12.3751 7.89099Z" fill="#39A3F8" />
                                            <path d="M10.8429 10.4165C11.2489 10.0075 11.7929 9.78198 12.3749 9.78198C12.9569 9.78198 13.5009 10.0075 13.9069 10.416C14.1014 10.612 14.1004 10.9285 13.9044 11.123C13.8064 11.22 13.6794 11.2685 13.5519 11.2685C13.4234 11.2685 13.2949 11.2195 13.1974 11.121C12.7629 10.6835 11.9869 10.6835 11.5524 11.121C11.3579 11.3165 11.0414 11.318 10.8454 11.1235C10.6494 10.929 10.6484 10.6125 10.8429 10.4165Z" fill="#39A3F8" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4355_17017">
                                                <rect width={24} height={24} fill="white" transform="translate(0.375)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                Airtime
                            </Link>
                        </li>
                        <li>
                            <Link to={'/data'}>
                                <div className="icon-box bg_color_2">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4355_17182)">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.1251 3.44482C11.8537 3.44482 11.5823 3.44929 11.3754 3.45819C7.2762 3.6346 3.36945 5.25275 0.34614 8.02658C0.0409477 8.30659 0.0419856 8.78279 0.334879 9.07568L1.73847 10.4793C2.03137 10.7722 2.50495 10.7704 2.81312 10.4937C5.17879 8.36971 8.20079 7.11796 11.3755 6.94708C11.7891 6.92482 12.4612 6.92482 12.8748 6.94708C16.0495 7.11796 19.0716 8.36971 21.4372 10.4937C21.7454 10.7704 22.219 10.7722 22.5119 10.4793L23.9154 9.07568C24.2083 8.78279 24.2094 8.30659 23.9042 8.02658C20.8809 5.25275 16.9742 3.63459 12.8749 3.45819C12.668 3.44929 12.3966 3.44482 12.1251 3.44482ZM12.0831 10.4116L11.3339 10.4416C9.09872 10.6112 6.9762 11.4953 5.28166 12.9627C4.9686 13.2338 4.97027 13.7111 5.26316 14.004L6.66667 15.4076C6.95956 15.7005 7.43228 15.6967 7.75477 15.437C8.79066 14.603 10.0444 14.0826 11.3663 13.9378L12.1251 13.8964C14.1994 14.0799 15.4569 14.6008 16.4955 15.437C16.818 15.6967 17.2907 15.7005 17.5836 15.4076L18.9872 14.004C19.2801 13.7111 19.2817 13.2338 18.9686 12.9627C17.2632 11.4859 15.1247 10.6002 12.8745 10.4385C12.4614 10.4089 12.1063 10.4116 12.0831 10.4116ZM12.1251 17.3954C11.854 17.3954 11.5828 17.4175 11.3809 17.4616C10.9771 17.5499 10.5921 17.7094 10.2442 17.9325C9.89616 18.1556 9.89855 18.6394 10.1914 18.9323L11.5949 20.3357C11.7355 20.4763 11.9263 20.5553 12.1252 20.5553C12.3241 20.5553 12.5148 20.4763 12.6555 20.3357L14.0589 18.9323C14.3518 18.6394 14.3542 18.1556 14.0062 17.9325C13.6582 17.7094 13.2732 17.5499 12.8694 17.4616C12.6674 17.4175 12.3963 17.3954 12.1251 17.3954Z" fill="#1E90FF" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.134 3.44482V6.93042C12.4023 6.93056 12.6702 6.93599 12.8746 6.94699C16.0493 7.11786 19.0714 8.36962 21.4371 10.4937C21.7453 10.7703 22.2189 10.7721 22.5118 10.4792L23.9153 9.07559C24.2082 8.7827 24.2093 8.3065 23.9041 8.02649C20.8808 5.25266 16.974 3.6345 12.8748 3.4581C12.6703 3.4493 12.4024 3.44492 12.134 3.44482ZM0.133981 8.39334C0.110813 8.49681 0.111064 8.60452 0.133981 8.70874V8.39334ZM12.134 10.4115V13.8971C14.2028 14.0814 15.4582 14.602 16.4954 15.437C16.8179 15.6966 17.2906 15.7004 17.5835 15.4075L18.9871 14.0039C19.28 13.711 19.2815 13.2337 18.9685 12.9626C17.2631 11.4858 15.1246 10.6001 12.8744 10.4384C12.5312 10.4138 12.2401 10.4116 12.134 10.4115ZM12.134 17.3955V20.5552C12.3298 20.5529 12.5169 20.474 12.6554 20.3356L14.0588 18.9322C14.3517 18.6393 14.3541 18.1556 14.0061 17.9324C13.6581 17.7093 13.273 17.5498 12.8692 17.4615C12.6695 17.4179 12.4021 17.396 12.134 17.3955Z" fill="#0584FF" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4355_17182">
                                                <rect width={24} height={24} fill="white" transform="translate(0.125)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                Data
                            </Link>
                        </li>

                        <li>
                            <Link to={'/tvs'}>
                                {/* <Link to={'/maitainance'}> */}
                                <div className="icon-box bg_color_4">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4355_16088)">
                                            <path d="M20.3247 10.3362C20.1985 10.1213 19.9678 9.98934 19.7186 9.98934H14.5737L15.2515 0.754543C15.2756 0.425527 15.0678 0.12398 14.7518 0.0294335C14.4357 -0.0653009 13.7855 -0.288481 13.625 -0.000246391L6.91695 12.9651C6.79568 13.1828 6.79878 13.4485 6.92506 13.6634C7.05134 13.8783 7.28201 14.0102 7.53124 14.0102H12.6762L11.625 23.9998H12.625C12.9436 24.0952 13.1548 23.9244 13.3139 23.6386L20.3329 11.0346C20.4541 10.8168 20.451 10.5511 20.3247 10.3362Z" fill="#C8A52A" />
                                            <path d="M19.3247 10.3364C19.1985 10.1215 18.9678 9.98956 18.7186 9.98956H13.5737L14.2515 0.754767C14.2756 0.425751 14.0678 0.124204 13.7518 0.0296573C13.4357 -0.0650771 13.0965 0.0729229 12.936 0.361157L5.91695 12.9653C5.79568 13.1831 5.79878 13.4487 5.92506 13.6636C6.05134 13.8785 6.28201 14.0105 6.53124 14.0105H11.6762L10.9983 23.2453C10.9742 23.5743 11.182 23.8758 11.4981 23.9704C11.8167 24.0658 12.1548 23.9246 12.3139 23.6389L19.3329 11.0348C19.4541 10.817 19.451 10.5513 19.3247 10.3364Z" fill="#F2C31C" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4355_16088">
                                                <rect width={24} height={24} fill="white" transform="translate(0.625)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                TV
                            </Link>
                        </li>
                        <li>
                            <Link to={"/electric"}>
                                <div className="icon-box bg_color_3">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.87511 1.25C5.49974 1.24954 5.1266 1.30757 4.76911 1.422C4.00043 1.65173 3.32708 2.12475 2.85029 2.76998C2.37349 3.4152 2.11901 4.19775 2.12511 5V10C2.12564 10.7292 2.41554 11.4284 2.93115 11.944C3.44676 12.4596 4.14592 12.7495 4.87511 12.75H8.87511C9.07402 12.75 9.26479 12.671 9.40544 12.5303C9.54609 12.3897 9.62511 12.1989 9.62511 12V5C9.62379 4.00585 9.22827 3.05279 8.5253 2.34981C7.82232 1.64684 6.86926 1.25133 5.87511 1.25Z" fill="#DA9B00" />
                                        <path d="M23.6248 6.00002V20C23.625 20.3612 23.5541 20.7189 23.416 21.0527C23.2779 21.3865 23.0753 21.6897 22.8199 21.9452C22.5645 22.2006 22.2612 22.4031 21.9275 22.5412C21.5937 22.6793 21.236 22.7503 20.8748 22.75H10.8748C10.5136 22.7503 10.1558 22.6793 9.82208 22.5412C9.48831 22.4031 9.18505 22.2006 8.92964 21.9452C8.67422 21.6897 8.47167 21.3865 8.33356 21.0527C8.19545 20.7189 8.1245 20.3612 8.12477 20V4.25002C8.12328 3.54389 7.87275 2.86092 7.41729 2.32131C6.96182 1.78171 6.33062 1.42006 5.63477 1.30002C5.70929 1.26345 5.79184 1.24626 5.87477 1.25002H18.8748C20.1342 1.25108 21.3418 1.75186 22.2324 2.64243C23.1229 3.533 23.6237 4.74057 23.6248 6.00002Z" fill="#FECC0E" />
                                        <path d="M15.875 8.75H12.875C12.6761 8.75 12.4853 8.67098 12.3447 8.53033C12.204 8.38968 12.125 8.19891 12.125 8C12.125 7.80109 12.204 7.61032 12.3447 7.46967C12.4853 7.32902 12.6761 7.25 12.875 7.25H15.875C16.0739 7.25 16.2647 7.32902 16.4053 7.46967C16.546 7.61032 16.625 7.80109 16.625 8C16.625 8.19891 16.546 8.38968 16.4053 8.53033C16.2647 8.67098 16.0739 8.75 15.875 8.75Z" fill="white" />
                                        <path d="M18.875 11.75H12.875C12.6761 11.75 12.4853 11.671 12.3447 11.5303C12.204 11.3897 12.125 11.1989 12.125 11C12.125 10.8011 12.204 10.6103 12.3447 10.4697C12.4853 10.329 12.6761 10.25 12.875 10.25H18.875C19.0739 10.25 19.2647 10.329 19.4053 10.4697C19.546 10.6103 19.625 10.8011 19.625 11C19.625 11.1989 19.546 11.3897 19.4053 11.5303C19.2647 11.671 19.0739 11.75 18.875 11.75Z" fill="white" />
                                        <path d="M18.875 14.75H12.875C12.6761 14.75 12.4853 14.671 12.3447 14.5303C12.204 14.3897 12.125 14.1989 12.125 14C12.125 13.8011 12.204 13.6103 12.3447 13.4697C12.4853 13.329 12.6761 13.25 12.875 13.25H18.875C19.0739 13.25 19.2647 13.329 19.4053 13.4697C19.546 13.6103 19.625 13.8011 19.625 14C19.625 14.1989 19.546 14.3897 19.4053 14.5303C19.2647 14.671 19.0739 14.75 18.875 14.75Z" fill="white" />
                                        <path d="M18.875 17.75H12.875C12.6761 17.75 12.4853 17.671 12.3447 17.5303C12.204 17.3897 12.125 17.1989 12.125 17C12.125 16.8011 12.204 16.6103 12.3447 16.4697C12.4853 16.329 12.6761 16.25 12.875 16.25H18.875C19.0739 16.25 19.2647 16.329 19.4053 16.4697C19.546 16.6103 19.625 16.8011 19.625 17C19.625 17.1989 19.546 17.3897 19.4053 17.5303C19.2647 17.671 19.0739 17.75 18.875 17.75Z" fill="white" />
                                    </svg>
                                </div>
                                Electricity
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>

            {/* navigation */}

            <div className="tf-container">
                <button type='button' onClick={() => setIsContact(!isContact)} style={{ marginTop: 40, backgroundColor: '#533dea', width: '30%', color: '#fff', padding: 15, borderRadius: 15 }}>Contact Us</button>
                &nbsp;&nbsp;
                {/* <Link to={'/pageant-queens'} className='bg-danger mr-3' style={{ marginTop: 40, width: '30%', color: '#fff', padding: 15, borderRadius: 15 }}>Vote Pageant</Link> */}

                {isContact && (<div className="card card-body mt-2">
                    <ul className='d-flex'>
                        <li><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/800px-WhatsApp.svg.png" style={{ height: 30, width: 30 }} alt="" /> : <b><a href="https://wa.me/2347036426747" _target="blank">07036426747</a></b> <br /><b><a href="https://wa.me/2349050093006" _target="blank">09050093006</a></b></li>

                        <li className='mx-3'>
                            <a _target="blank" href="https://www.facebook.com/Ssubme"> <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Facebook_icon.svg/2048px-Facebook_icon.svg.png" style={{ height: 30, width: 30 }} alt="" /> </a>
                        </li>
                        <li className='mx-3'>
                            <a _target="blank" href="https://x.com/SsubMe"> <img src="https://www.unipile.com/wp-content/uploads/2022/09/logo_instagram.png" style={{ height: 30, width: 30 }} alt="" /> </a>
                        </li>
                        <li>
                            <a _target="blank" href="https://www.instagram.com/Ssubme"> <img src="https://logos-world.net/wp-content/uploads/2023/08/X-Logo.jpg" style={{ height: 30, width: 34 }} alt="" /> </a>
                        </li>

                    </ul>
                </div>)}
            </div>
        </div>

    )
}

export default Home