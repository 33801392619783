import React, { useState } from 'react'
import Loader from '../../../components/Loader'
import { PostPegeants } from '../../../services/admin_service';
import { useNavigate } from 'react-router-dom';

const CreatePageant = () => {
    const navigation = useNavigate()
    const [data, setData] = useState({
        season: '',
        description: '',
        vote_price: '',
        start_date: '',
        end_date: ''
    });
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null);
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(data)
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('cover_image', file);
            formData.append('season', data.season);
            formData.append('description', data.description);
            formData.append('vote_price', data.vote_price);
            formData.append('start_date', data.start_date);
            formData.append('end_date', data.end_date);

            // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
            PostPegeants(formData).then((res) => {
                setLoading(false)
                console.log(res.data)
                if (res?.data.status) {
                    navigation('/admin')
                }
            })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }
    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            {/* /preload */}
            <div className="header is-fixed">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <a href="#" className="back-btn"> <i className="icon-left" /> </a>
                        <h3>Create Pegeants</h3>
                    </div>
                </div>
            </div>
            <div id="app-wrap" className="style1">
                <div className="tf-container">
                    <form onSubmit={handleSubmit}>
                        <h3>Please Fill Carefully</h3>

                        <div className="group-input">
                            <label>Season Title</label>
                            <input type="text" required value={data.season} name='season' onChange={handleChange} placeholder="New Season" />
                        </div>
                        <div className="group-input">
                            <label>Description</label>
                            <textarea name='description' onChange={handleChange} value={data.description}></textarea>
                        </div>
                        <div className="group-input">
                            <label>Voting Price</label>
                            <input type="number" required onChange={handleChange} value={data?.vote_price} name='vote_price' placeholder="Voting price" />
                        </div>
                        <div className="group-input">
                            <label>Start Date</label>
                            <input type="date" required onChange={handleChange} value={data?.start_date} name='start_date' placeholder="Start date" />
                        </div>
                        <div className="group-input">
                            <label>End Date</label>
                            <input type="date" onChange={handleChange} value={data?.end_date} name='end_date' placeholder="End date" />
                        </div>
                        <div className="group-input">
                            <label>Feature Image</label>
                            <input type="file" id="image" required accept="image/*" onChange={handleFileChange} />
                        </div>
                        <div className="group-input">
                            <button type='submit' className='btn-outline-primary'>Submit</button>
                        </div>
                    </form>
                </div>
                <div style={{ marginBottom: 80 }}></div>
            </div>
        </div>
    )
}

export default CreatePageant