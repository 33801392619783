import moment from "moment"
import { Link } from "react-router-dom"
import { GetLatestPegeants, PostVote } from "../services/admin_service"
import { useEffect, useState } from "react"
import Loader from "../components/Loader"
import { ToastContainer, toast } from "react-toastify"
import { useSelector } from "react-redux"

const PageantQueens = () => {
    const { user } = useSelector((state) => state.user)
    const [pegeant, setPegeants] = useState([])
    const [votes, setVotes] = useState("")
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getIncome()
    }, [])

    const getIncome = async () => {
        setLoading(true)
        await GetLatestPegeants().then((res) => {
            setLoading(false)
            // console.log(res.data)
            if (res.data.status) {
                setPegeants(res.data.returnObj)
            }
        }).catch(err => {
            // toast.error(err.message)
            setLoading(false)
            console.log(err.message)
        })
    }

    const handleVote = async (item) => {
        setLoading(true)
        if (votes == "" || votes == undefined) {
            return toast.error("Enter the number of vote you want to give")
        }
        let model = {
            email: user?.email,
            amount: pegeant?.vote_price,
            vote: votes
        }
        // console.log(model)

        await PostVote(model, item?.id).then((res) => {
            setLoading(false)
            console.log(res.data)
            if (res.data.status) {
                toast.success("Thank you for voting your interest Queen, You are too much")
                // window.location.reload()
            } else {
                toast.error(res.data.message)
            }
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
            console.log(err)
        })
    }

    return (
        <div>
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}
            <div className="">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to={'/'} className="back-btn"> <i className="icon-left" /> </Link>
                        <h3>{pegeant?.season}</h3>
                    </div>
                </div>
            </div>
            <div>
                <div className="mb-8">
                    <img src={`https://ssubme.com/backend/manage/public/cover_image/${pegeant?.cover_image}`} alt="" className='img-responsive' />
                    {/* <img src="https://ichef.bbci.co.uk/news/624/mcs/media/images/72037000/jpg/_72037705_019932218-1.jpg" alt="" className='img-responsive' /> */}
                    <div className="app-section bg_white_color giftcard-detail-section-1">
                        <div className="tf-container">
                            <div className="voucher-info">
                                <h2 className="fw_6">{pegeant?.season} - {pegeant?.start_date}</h2>
                                <a href="#" className="critical_color fw_6">NGN {pegeant?.vote_price}</a>
                            </div>
                            <div className="mt-2">
                                <a href="#" className="note-voucher">Voting on now</a>
                                <p className="mt-2 fw_4">Offers to {moment(pegeant?.end_date).format('do MMM yyyy')} </p>
                            </div>
                        </div>
                    </div>
                    <div className="app-section mt-1 bg_white_color giftcard-detail-section-2">
                        <div className="tf-container">
                            <div className="voucher-desc">
                                <h4 className="fw_6">Description</h4>
                                <p className="mt-1"><span>{pegeant?.description}</span> stores</p>
                            </div>
                        </div>
                    </div>
                    <div className="app-section mt-1 bg_white_color giftcard-detail-section-2">
                        <div className="tf-container">
                            <div className="d-flex">
                                <h3>List of Queens </h3>
                            </div>
                            <div className="tab-gift-item mt-5">
                                {pegeant?.queens?.map((item, index) => (
                                    <div className="food-box" style={{ cursor: 'pointer' }} key={index}>
                                        <div className="img-box">
                                            {/* <img src={`${item?.cover_image}`} alt="images" /> */}
                                            <img src={`https://ssubme.com/backend/manage/public/image/${item?.image}`} alt="images" />
                                            <span>{item?.votes}</span>
                                        </div>
                                        <div className="content">
                                            <h4>{item.queen_name}</h4>
                                            <div className="rating mt-2">
                                                {/* <span>{moment(item?.end_date).format('do MMM yyyy')}</span> */}
                                                <span className="text-danger">cost NGN {pegeant?.vote_price} / vote</span>
                                            </div>
                                            <input type="number" onChange={(e) => setVotes(e.target.value)} className="my-2" placeholder="votes" />
                                            <a className="btn bg-warning" onClick={() => handleVote(item)}>Vote Now!</a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>


                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
export default PageantQueens