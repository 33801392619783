import React from 'react'
import { useSelector } from 'react-redux'

const BankTransfer = () => {
    const { user } = useSelector((state) => state.user)
    return (
        <div>
            <div className="app-header st1">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"><i className="icon-left white_color" /></a>
                        <h3 className="white_color">Bank Transfer</h3>
                    </div>
                </div>
            </div>

            <div className="transfer-card">
                <div className="tf-container">
                    {/* <div className="tf-card-block d-flex align-items-center justify-content-between">
                        <div className="inner d-flex align-items-center">
                            <i className="logo icon-wallet-filled-money-tool" />
                            <div className="content">
                                <small>my wallet account</small>
                                <h4> <a className="fw_6">{user?.userData.bank_name}</a></h4>
                                <p><a href={`tel:${user?.userData.account_number}`}> {user?.userData.account_number}</a></p>
                                <p>{user?.userData.account_name}</p>
                            </div>
                        </div>
                    </div> 
                    <hr />*/}
                    <h6 className="text-center">Account Transfer</h6>
                    <hr />
                    <div className="tf-card-block d-flex align-items-center justify-content-between">
                        <div className="inner d-flex align-items-center">
                            <i className="logo icon-wallet-filled-money-tool" />
                            <div className="content">
                                <h4> <a className="fw_6">Moniepoint MFB</a></h4>
                                <p><a href="tel:6390342594"> 6390342594</a></p>
                                <p>Miss Okpella</p>
                            </div>
                        </div>
                    </div>
                    <div className="tf-card-block d-flex align-items-center justify-content-between">
                        <div className="inner d-flex align-items-center">
                            <i className="logo icon-wallet-filled-money-tool" />
                            <div className="content">
                                <h4> <a className="fw_6">Opay MFB</a></h4>
                                <p><a href="tel:7036426747"> 7036426747</a></p>
                                <p>Kadiri Godson</p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default BankTransfer