import React, { useEffect, useState } from 'react'
import { GetDeletedUsers, RestoreUser } from '../../services/admin_service'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'

const DeletedUsers = () => {
    const { user } = useSelector((state) => state.user)
    const [allUsers, setAllUsers] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [retryCount, setRetryCount] = useState(false)

    useEffect(() => {
        getAllUsers()
    }, [])

    const getAllUsers = async () => {
        setLoading(true)
        await GetDeletedUsers(user?.token).then((el) => {
            setLoading(false)
            setAllUsers(el.data.users)
            // console.log(el.data)
        }).catch(err => {
            setLoading(false)
            setRetryCount(true)
            toast.error("Something went wrong, please contact the page")


        })
    }



    const restoreUser = async (item) => {
        setLoading(true)
        let model = {
            email: item
        }
        if (window.confirm("Are you sure you want to remove?")) {
            await RestoreUser(model, user?.token)
                .then((result) => {
                    setLoading(false)
                    toast.success(result.data.message)
                    getAllUsers()
                }).catch(err => {
                    setLoading(false)
                    console.log(err.message)
                    toast.error("Something went wrong please refresh the page")
                })
        }

    }

    const filteredUsers = allUsers?.filter((user) => {
        const fullName = `${user?.name} ${user?.email} ${user?.role}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
    });


    return (
        <div className="panel-box panel-up">
            {loading && <div style={{ position: 'absolute', top: '40%', left: '40%' }}><Loader /></div>}

            {retryCount && <div style={{ position: 'absolute', top: '50%' }}>
                <p className="text-danger">Like there's network issue</p>
                <button className='btn btn-warning' onClick={() => { window.location.reload() }}>Retry!</button>
            </div>}
            <div className="header-transfer header-st2">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex align-items-center">
                        <Link to={'/admin'} className="clear-panel"><i className="icon-left on_surface_color" /></Link>
                        <h3 className>All Users</h3>
                    </div>
                </div>
            </div>
            <div className="wrap-transfer-friends mt-3">
                <div className="tf-container">

                    <div style={{ marginTop: 50, marginBottom: 50 }}>
                        <div className="mt-4">
                            <input type='text' className='form-control my-3' placeholder='Search User...' value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)} />
                            <hr />

                            {filteredUsers?.length > 0 ? (<ul className="tabs-list-item" style={{ display: 'block' }}>
                                {filteredUsers?.map((el, index) => (
                                    <li key={index}>
                                        <a href="#" className="">
                                            <ul className="inner">
                                                <li className="user">
                                                    <img src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" style={{ height: 30, width: 30 }} alt="image" />
                                                </li>
                                                <li className="info">
                                                    <h4>{el?.name}</h4>
                                                    <p>{el?.phone} {"."} {el?.email}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p>{el?.role}</p>
                                                        {/* <span>₦{el?.wallet?.balance}</span> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </a>
                                        <div className="d-flex mb-2">

                                            <a href="#" className="btn btn-primary" onClick={() => restoreUser(el?.email)}>Restore</a>
                                        </div>


                                        <hr />
                                    </li>
                                ))}
                            </ul>
                            ) : (
                                <ul className="tabs-list-item" style={{ display: 'block' }}>

                                    {allUsers?.map((el, index) => (
                                        <li key={index}>
                                            <a href="#" className="">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png" style={{ height: 30, width: 30 }} alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>{el?.name}</h4>
                                                        <p>{el?.phone} {"."} {el?.email}</p>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p>{el?.role}</p>
                                                            {/* <span>₦{el?.wallet?.balance}</span> */}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </a>



                                            <hr />
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default DeletedUsers