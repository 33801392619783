import { useEffect } from 'react';
import { Offline, Online } from "react-detect-offline";
import Layout from './layouts/Layout'
import { ToastContainer, toast } from 'react-toastify';

function App() {
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // alert("Complete Cache Cleared");
  };

  useEffect(() => {
    clearCacheData()
  }, [])

  const setTime = (text) => {
    toast.success(text)
  }

  return (
    <>
      <Layout />
      <Online>
        {setTime("You are online")}
      </Online>
      <Offline>
        <p style={{ backgroundColor: "orangered", color: 'white', textAlign: 'center' }}>You are offline. Please check your internet connection.</p>
      </Offline>
      <ToastContainer />
    </>
  )
}

export default App
